import React, { Component } from "react";
import LinkButton from "./linkButtonBlock";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import SystemLogo from "./systemLogoBlock";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";

class SimpleMessage extends Component {
  messageBlock = (logo, placement, title, content, link, label) => {
    let divClass = "pb-5 pt-3 ";
    if (placement === "center") {
      divClass += "text-center";
    }

    var logodisplay = "";
    if (logo === "smiley") {
      logodisplay = (
        <SentimentVerySatisfiedIcon
          style={{ fontSize: "150px", color: "green" }}
        />
      );
    } else if (logo === "check") {
      logodisplay = (
        <CheckCircleOutlineIcon style={{ fontSize: "150px", color: "green" }} />
      );
    } else if (logo === "error") {
      logodisplay = <SystemLogo />;
    } else if (logo === "emailsent") {
      logodisplay = (
        <EmailOutlinedIcon
          className="mycatholicsgred"
          style={{ fontSize: "80px" }}
        />
      );
    }
    return (
      <React.Fragment>
        <div className={divClass}>
          {logo && <div>{logodisplay}</div>}
          {title && <h1 className="pb-2">{title}</h1>}

          {!logo && <h5 className="py-2">{content}</h5>}
          {logo && <h4 className="py-2">{content}</h4>}
        </div>
        {link && <LinkButton link={link} label={label} />}
      </React.Fragment>
    );
  };

  render() {
    const { logo, placement, title, content, link, label } = this.props;
    return this.messageBlock(logo, placement, title, content, link, label);
  }
}

export default SimpleMessage;
