import React, { Component } from "react";
import Header from "./common/header";
import { Link } from "react-router-dom";
import { Button, Carousel, Image, Modal } from "react-bootstrap";
import {
  getCurrentUserData,
  updateUserProductList,
  userLogout,
} from "../services/user";
import { getSuperAdminCode, formatTime } from "../utils/utils";
import LoadingSpinnerNav from "./common/loadingSpinnerNavbar";
import WatchLaterOutlinedIcon from "@material-ui/icons/WatchLaterOutlined";
import StepMenuCard from "./blocks/stepsMenuCard";
import { getAllAnnouncementsByParish } from "./announcement/service/announcement";
import Linkify from "react-linkify";
import { isValidFullname } from "../services/validation";
import Footer from "./common/footer";
import BottomBar from "./common/bottomBar";
import StorefrontTwoToneIcon from "@material-ui/icons/StorefrontTwoTone";
import fire from "../services/fire";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import AvatarEditor from "react-avatar-editor";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { Grid, Slider } from "@material-ui/core";
import SpinnerText from "./common/spinnerwithText";
import SuccessMessage from "./common/successMessageBox";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      progress: 0,
      isuploading: false,
      menutype: "light",
      inputdata: {
        fullname: "",
        email: "",
      },
      caption: "",
      state: "account_created",
      announcementlist: [],
      systemannouncementlist: [],
      user: this.props.user,
      loading: true,
      saving: false,
      zoom: 1,
      products: [],
    };
  }

  getState = (user, userData) => {
    const { emailVerified } = user;
    const { storelink } = userData;
    let state = "ready";
    if (!storelink) {
      state = "setup_store";
    }

    if (!emailVerified) {
      state = "verify_email";
    }
    // state = "verify_email";
    // state = "setup_store";
    return state;
  };

  handleVerifyEmail = () => {
    this.setState({ show: true });
  };

  handleSetupStore = () => {
    this.setState({ show: true });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  setupStore = () => {
    this.props.history.push("/account/edit");
  };

  getStateMenuActions = (state) => {
    let action = null;
    if (state === "verify_email") {
      action = this.handleVerifyEmail;
    } else if (state === "setup_store") {
      action = this.handleSetupStore;
    }
    return action;
  };

  getStateButtonActions = (state) => {
    let action = null;
    if (state === "verify_email") {
      action = this.handleClose;
    } else if (state === "setup_store") {
      action = this.handleSetupStore;
    }
    return action;
  };

  getCurrentUserData = async () => {
    try {
      const { uid } = this.state.user;

      const user = await getCurrentUserData(uid);

      if (user) {
        const {
          fullname,
          email,
          mobile,
          dob,
          created,
          storename,
          storecontact,
          storelink,
          bigpic,
          products,
        } = user;

        const newstate = this.getState(this.state.user, user);
        //check if valid fullname
        let isvalidfullname = isValidFullname(fullname);
        //is email verified
        this.setState({
          isvalidfullname,
          inputdata: {
            created,
            fullname,
            email,
            mobile,
            dob,
            storename,
            storecontact,
            storelink,
            bigpic,
            products,
          },
          caption: "",
          state: newstate,
          loading: false,
        });
      } else {
        //USER NOT FOUND
        //LOGOUT!!!
        await userLogout();
        window.location.reload();
      }
    } catch (error) {
      console.log("Error:", error);
      //USER NOT FOUND
      //LOGOUT!!!
      await userLogout();
      window.location.reload();
    }
  };

  async componentDidMount() {
    await this.getCurrentUserData();
    window.scrollTo(0, 0);
  }

  async getAnnouncements() {
    //retrieve data from firestore
    const { parish } = this.state.inputdata;
    if (!parish) {
      return;
    }
    try {
      const announcement = await getAllAnnouncementsByParish(parish);
      //parse here

      //extract system
      const systemnotification = announcement.filter(
        (item) => item.parish === getSuperAdminCode()
      );
      const publicnotification = announcement.filter(
        (item) => item.parish !== getSuperAdminCode()
      );
      this.setState({
        announcementlist: publicnotification,
        systemannouncementlist: systemnotification,
        loading: false,
      });
    } catch (error) {
      console.error("Announcements not yet created");
      this.setState({ loading: false });
    }
  }

  displayBasicNotification(item) {
    return (
      <Carousel.Item key={item.id}>
        <div className="d-block w-100">
          <h3>{item.header}</h3>
          <p>{item.content}</p>
        </div>
      </Carousel.Item>
    );
  }

  getAnnouncementLink(item) {
    const label = item.header;
    const link = `/announcement/view/${item.id}`;
    return <Link to={link}>{label}</Link>;
  }

  componentDecorator = (href, text, key) => (
    <a href={href} key={key} rel="noopener noreferrer" target="_blank">
      {text}
    </a>
  );

  displayNotification(item) {
    let substr = item.content;
    let timeago = Date.now() - item.created;
    if (timeago < 7 * 24 * 60 * 60 * 1000) {
      timeago = formatTime(item.created);
      timeago = (
        <span className={` badge badge-pill badge-danger`}>{timeago}</span>
      );
    } else {
      timeago = null;
    }
    return (
      <Carousel.Item key={item.id}>
        <div className="d-block w-100">
          <div
            className="alert alert-primary mb-0 py-3 announcementpanelblue"
            role="alert"
            key={item.id}
            style={{
              paddingLeft: "40px",
              paddingRight: "40px",
            }}
          >
            <p className="mb-0 text-truncate announcementpanelheaderblue defaultfontsize font-weight-bold">
              {this.getAnnouncementLink(item)}
            </p>
            <p className="mb-0 lineentry">
              <Linkify componentDecorator={this.componentDecorator}>
                {substr}
              </Linkify>
            </p>
            <div className="text-truncate">
              <WatchLaterOutlinedIcon
                style={{ fontSize: "14px", color: "#f00" }}
              />{" "}
              <small>
                {new Date(item.created).toDateString()} {timeago}
              </small>{" "}
            </div>
          </div>
        </div>
      </Carousel.Item>
    );
  }

  getSystemAnnouncements() {
    const { announcementlist } = this.state;

    let content;
    content = (
      <React.Fragment>
        {announcementlist.length > 0 && (
          <Carousel indicators={false} controls={true}>
            {announcementlist.map((item) => {
              return this.displayNotification(item);
            })}
          </Carousel>
        )}
      </React.Fragment>
    );

    return content;
  }

  getMainMenuPanels = () => {
    let { menutype, state } = this.state;
    let action = this.getStateMenuActions(state);
    // console.log(`state: ${state} action: ${action}`);
    let createaccount = (
      <StepMenuCard
        menulink={() => {}}
        menulinklabel="Create your store"
        menuicon="completed"
        menutype={menutype}
      />
    );
    let verifyemail = (
      <StepMenuCard
        menulink={state === "verify_email" ? action : null}
        menulinklabel="Verify your email"
        menuicon={state === "verify_email" ? "next" : "completed"}
        menutype={menutype}
      />
    );
    let setup = (
      <StepMenuCard
        menulink={state === "setup_store" ? action : null}
        menulinklabel="Setup your store"
        menuicon={state === "setup_store" ? "next" : "completed"}
        menutype={menutype}
      />
    );

    return (
      <div className="row justify-content-center">
        <div className="container">
          {createaccount}
          {verifyemail}
          {setup}
        </div>
      </div>
    );
  };

  doLogout = async () => {
    try {
      await userLogout();
      window.location.reload();

      // signed out
    } catch (e) {
      // an error
      window.location.reload();
    }
    //potentially force redirect or refresh here
  };

  getDialogContents = () => {
    const state = this.state.state;
    const user = this.state.inputdata;
    let title = "",
      body = "",
      buttonlabel = "",
      buttonaction = "";
    if (state === "verify_email") {
      title = "Verify Email";
      buttonlabel = "Close";
      buttonaction = this.handleClose;
      body = (
        <>
          We have sent you an email at{" "}
          <b className="text-primary">{user.email}</b>
          <br />
          <br />
          Please check your email and <b>click the verification link</b> to
          confirm your account ownership.
          <br />
          <br />
          If you don't receive it, please check your spam or wait for few more
          minutes.
          <br />
          <br />
          Alternatively, you may reset your password.
        </>
      );
    } else if (state === "setup_store") {
      title = "Almost There!";
      buttonlabel = "Setup Now";
      buttonaction = this.setupStore;
      body = (
        <div className="text-left">
          Thank you for verifying your email.
          <br />
          <br />
          Now, let us setup your online store!
          <br />
          <br />
        </div>
      );
    }
    return [title, body, buttonlabel, buttonaction];
  };

  displayDialogBox = () => {
    const { show } = this.state;
    const [title, body, buttonlabel, buttonaction] = this.getDialogContents();
    return (
      <Modal
        show={show}
        animation={false}
        centered
        backdrop="static"
        keyboard={false}
        onHide={this.handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="text-primary">{title}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={buttonaction}>
            {buttonlabel}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  handleButtonClick = () => {
    const { inputdata } = this.state;
    window.open(`https://m.me/${inputdata.storecontact}`, "_blank");
  };

  handleChange = (e) => {
    this.setState({ caption: e.target.value });
  };

  handlePriceChange = (e) => {
    this.setState({ price: e.target.value });
  };

  displayImages = (list) => {
    let style = "col-6 text-center p-1  zoom pointer";
    if (list.length === 1) {
      style = "col-lg-8 text-center py-3 mx-auto zoom pointer";
    } else {
      if (list.length === 2) {
        style = "col-6 text-center py-3 zoom pointer";
      }
    }
    console.log({ list });

    return list.map((item) => (
      <div key={item.timestamp} className={`${style}`}>
        <Zoom>
          <img alt="" className="responsive" src={item.image} />
        </Zoom>

        <Link className="text-left text-dark" to={`/v/${item.id}`}>
          <p className="mb-0 text-left font-weight-bold twolineentry">
            {item.caption}
          </p>
        </Link>

        {item.price && <p className="text-left text-danger">{item.price}</p>}
      </div>
    ));
  };

  getDisplayContent = () => {
    const { state, inputdata, zoom, saving, imageDataUrl } = this.state;
    const photoUrl = inputdata.bigpic;
    let display = null;
    if (state === "ready") {
      display = (
        <>
          {!photoUrl && (
            <StorefrontTwoToneIcon
              className="text-primary"
              style={{ fontSize: "100px" }}
            />
          )}
          {photoUrl && (
            <Image
              src={photoUrl}
              width="100px"
              height="100px"
              alt=""
              roundedCircle
            />
          )}

          <h3 className="py-0 mb-0">{inputdata.storename}</h3>
          {inputdata.storelink && (
            <>
              <StorefrontTwoToneIcon
                className="text-primary"
                style={{ fontSize: "16px" }}
              />
              /{inputdata.storelink}
              <div className="clearfix"></div>
              <Link
                className="mt-2 btn-sm btn  btn-outline-secondary"
                role="button"
                to={`/${inputdata.storelink}`}
              >
                Visit Shop
              </Link>
            </>
          )}
          <div className="clearfix"></div>
          {/* {!inputdata.products && (
            <h4 className="py-4 my-4 text-muted">Let's add some products.</h4>
          )} */}

          <div className="text-right py-3 text-primary">
            <label htmlFor="file-input" className="image-upload-icon">
              <AddOutlinedIcon fontSize="small" /> Add Product
            </label>

            <input
              id="file-input"
              hidden
              type="file"
              onChange={this.onFileChange}
              accept="image/*"
            />
          </div>
          <div className="row ">
            {inputdata.products && this.displayImages(inputdata.products)}
          </div>
          <BottomBar
            label="Message to Order"
            onclick={this.handleButtonClick}
          />
        </>
      );
    } else if (state === "upload_product") {
      console.log(this.state.caption);
      console.log(parseInt(this.state.price));
      let disable = false;

      if (!this.state.caption || !this.state.price) {
        disable = true;
      } else {
        let caption = this.state.caption.trim();
        let price = this.state.price.trim();
        if (caption.length === 0 || price.length === 0) {
          disable = true;
        }
        if (price.length > 0) {
          if (parseInt(price.replace(/\D+/g, "")) > 0) {
            disable = false;
          } else {
            disable = true;
          }
        }
      }
      display = (
        <div className="mx-auto">
          <h4 className="text-left pt-0 pb-3">Add Product Photo</h4>
          <div className="pb-0 responsive">
            <AvatarEditor
              ref={this.setEditorRef}
              image={imageDataUrl}
              width={680}
              height={680}
              border={0}
              borderRadius={340}
              scale={zoom}
              crossOrigin="anonymous"
              style={{
                width: "100%",
                height: "auto",
                maxWidth: "380px",
              }}
            />
          </div>
          <div className="py-1">
            <Grid container spacing={2}>
              <Grid item>
                <RemoveIcon onClick={this.decreaseZoom} />
              </Grid>
              <Grid item xs>
                <Slider
                  className="text-primary"
                  value={this.state.zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, zoom) => this.onZoomChange(zoom)}
                />
              </Grid>
              <Grid item>
                <AddIcon onClick={this.increaseZoom} />
              </Grid>
            </Grid>
          </div>

          <div className="form-group text-left">
            <label htmlFor="caption">Product Name</label>

            <input
              name="caption"
              id="caption"
              placeholder="ex. T-shirt"
              className="form-control form-control-lg"
              autoComplete="none"
              onChange={this.handleChange}
            />
          </div>
          <div className="form-group text-left">
            <label htmlFor="caption">Price</label>

            <input
              name="price"
              id="price"
              placeholder="ex. P150"
              className="form-control form-control-lg"
              autoComplete="none"
              onChange={this.handlePriceChange}
            />
          </div>
          {!saving && (
            <Button
              variant="primary"
              disabled={disable}
              className="btn-block btn-lg"
              onClick={this.onClickSave}
            >
              Save
            </Button>
          )}
          {saving && (
            <Button variant="primary" className="btn-block btn-lg" disabled>
              <SpinnerText text="Saving..." />
            </Button>
          )}
          <Button
            variant="link"
            className="btn-block btn-lg text-primary"
            onClick={this.onClickCancel}
          >
            Cancel
          </Button>
        </div>
      );
    } else if (state === "photo_upload_success") {
      display = (
        <SuccessMessage
          message="Your photo has been successfully saved!"
          label="Done"
        />
      );
    } else {
      display = (
        <div className="text-left">
          <h3 className="pt-0 mt-0 pb-1 mb-0 text-muted font-weight-light">
            Welcome,
          </h3>
          <h3 className="pt-0 pb-3">{inputdata.fullname}</h3>
          {this.getMainMenuPanels()}
          {this.displayDialogBox()}
          <Footer />
        </div>
      );
    }
    return display;
  };

  onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      // const { data } = this.state;
      const file = e.target.files[0];
      let imageDataUrl = await this.readFile(file);

      // logEvent("profile_new_photo_upload", { email: data.email });

      this.setState({ imageDataUrl, state: "upload_product" });
    }
  };

  readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  onClickCancel = () => {
    this.setState({ state: "ready" });
  };

  onClickSave = async () => {
    if (this.editor) {
      const { price, caption, inputdata } = this.state;

      this.setState({ saving: true });
      const uniqNum = new Date().getTime();
      const canvasScaled = this.editor.getImageScaledToCanvas();
      const userid = this.state.user.uid;
      const uploadpath = `images/${userid}/products/${uniqNum}`;
      const filename = `${uniqNum}`;
      const products = inputdata.products;

      fire
        .storage()
        .ref(uploadpath)
        .child(filename)
        .putString(canvasScaled.toDataURL(), "data_url")
        .then(async (snapshot) => {
          await this.updateProductList(
            userid,
            products,
            filename,
            caption,
            price
          );
          // logEvent("profile_photo_changed_success", { email: data.email });
          // console.log(
          //   `User ID: ${userid} Filename: ${filename} Caption: ${caption}`
          // );
          this.setState({
            saving: false,
            state: "photo_upload_success",
            photoUrl: canvasScaled.toDataURL(),
          });
        });
    }
  };

  updateProductList = async (userid, list, filename, caption, price) => {
    const imageDir = `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/images`;
    const thumb = encodeURIComponent(
      `/${userid}/products/${filename}/thumbs/${filename}_200x200`
    );
    const photo = encodeURIComponent(
      `/${userid}/products/${filename}/thumbs/${filename}_680x680`
    );
    const smallPic = `${imageDir}${thumb}?alt=media`;
    const bigPic = `${imageDir}${photo}?alt=media`;
    const prodlistRef = fire.firestore().collection("products").doc();
    const newObj = {
      id: prodlistRef.id,
      timestamp: parseInt(filename),
      filename,
      caption,
      price,
      thumb: smallPic,
      image: bigPic,
      author: userid,
    };

    await prodlistRef.set(newObj);

    let productlist;
    if (list) {
      list.unshift(newObj);
      productlist = list;
    } else {
      productlist = [newObj];
    }

    await updateUserProductList(userid, productlist);
  };

  setEditorRef = (editor) => (this.editor = editor);

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };

  increaseZoom = () => {
    const zoom = this.state.zoom;
    let newzoom = 3;
    if (zoom < 3) {
      newzoom = zoom + 0.1;
    }
    this.setState({ zoom: newzoom });
  };

  decreaseZoom = () => {
    const zoom = this.state.zoom;
    let newzoom = 1;
    if (zoom > 1) {
      newzoom = zoom - 0.1;
    }
    this.setState({ zoom: newzoom });
  };

  render() {
    const { loading, user, state } = this.state;
    const hide = state !== "ready" ? "profile" : null;
    if (loading === true) {
      return (
        <LoadingSpinnerNav
          user={user}
          smallpic={user.photoURL}
          hidehome={true}
          hide={hide}
        />
      );
    }
    return (
      <React.Fragment>
        <Header
          user={user}
          smallpic={user.photoURL}
          hide={hide}
          hidehome={true}
        />
        <div className="row justify-content-center mx-0">
          <div className="col-lg-8 px-1">
            <main className="container-fluid text-center pt-4">
              {this.getDisplayContent()}
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
