import {
  isArchbishopRole,
  isArchCommsRole,
  isParishPriestRole,
  isSecretaryRole,
  isSuperAdminRole,
  isSupportRole,
  isSystemAdminRole,
  isAttendanceVerifierRole,
  isSystemValidatorRole,
} from "../../roles/service/roles";

export function showAdminMenu(user) {
  if (
    isArchbishopRole(user) ||
    isArchCommsRole(user) ||
    isParishPriestRole(user) ||
    isSecretaryRole(user) ||
    isSuperAdminRole(user) ||
    isSupportRole(user) ||
    isAttendanceVerifierRole(user) ||
    isSystemAdminRole(user) ||
    isSystemValidatorRole(user)
  ) {
    return true;
  }

  return false;
}
