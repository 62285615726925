import React from "react";
import { Link } from "react-router-dom";
import Joi from "joi-browser";
import Form from "../../common/form";
import fire from "../../../services/fire";
import SystemLogo from "../../blocks/systemLogoBlock";
import { logEvent } from "../../../services/log";

class LoginForm extends Form {
  state = {
    data: { email: "", password: "" },
    errors: {},
    loading: false,
  };

  schema = {
    email: Joi.string().email().trim().required().label("Email"),
    password: Joi.string().min(8).trim().required().label("Password"),
  };

  doSubmit = () => {
    // Call the server
    const { data, errors } = this.state;
    this.setState({
      errors,
      loading: true,
    });
    let lowercaseemail = data.email.trim().toLowerCase();
    logEvent("login_click", { email: lowercaseemail });
    fire
      .auth()
      .signInWithEmailAndPassword(lowercaseemail, data.password.trim())
      .then((u) => {
        logEvent("login_success", { email: lowercaseemail });
        this.props.history.push("/");
      })
      .catch((error) => {
        let errors;
        if (error.code === "auth/too-many-requests") {
          errors = { email: error.message };
        } else {
          errors = {
            email: "Incorrect email or password",
          };
        }
        logEvent("login_fail", {
          email: lowercaseemail,
          message: error.message,
        });
        this.setState({
          loading: false,
          errors,
        });
      });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { loading } = this.state;

    return (
      <React.Fragment>
        <div className="my-4 pt-4"></div>
        <SystemLogo />
        <div className="row justify-content-center mx-auto">
          <div className="col-lg-4">
            <main className="container">
              <div className="pb-3 pt-4">
                <h4 className="pb-2">Log in to your store</h4>
                <form onSubmit={this.handleSubmit}>
                  {this.renderInput("email", "Email", "text", "Your email")}
                  <div className="forgotpassword float-right">
                    <a rel="noopener noreferrer" href="/forgot">
                      Forgot password?
                    </a>
                  </div>
                  {this.renderInput(
                    "password",
                    "Password",
                    "password",
                    "8 characters or more"
                  )}
                  {!loading && this.renderBlockButton("Log In")}
                  {loading && this.renderLoadingBlockButton("Logging in...")}
                </form>
              </div>

              <div className="text-center">
                <p className="pt-0 pb-0 normalsmallfontsize text-muted">
                  Don't have a store?{" "}
                  <Link to="/register">Create store</Link>
                </p>
              </div>
              <div className="col-12 mt-5 mb-4 text-center">
                <p className="mt-3 mb-0 text-muted">
                  &copy; {new Date().getFullYear()} PalitNa Beta.
                  <br /> All Rights Reserved.
                </p>
              </div>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default LoginForm;
