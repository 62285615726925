import React from "react";

const Footer = () => {
  return (
    <React.Fragment>
      <div className="clearfix"></div>
      <div className="col-12 py-3 mt-5 mb-4 text-center">
        <p className="mt-3 mb-0 text-muted">
          &copy; {new Date().getFullYear()} PalitNa Beta.
          <br /> All Rights Reserved.
        </p>
        {/* <p className="my-0 text-muted">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.catholic.sg/terms/"
            className="text-muted"
          >
            Terms & Conditions
          </a>
        </p> */}
      </div>
    </React.Fragment>
  );
};

export default Footer;
