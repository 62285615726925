import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import DvrOutlinedIcon from "@material-ui/icons/DvrOutlined";
import { showAdminMenu } from "../admin/service/admin";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import StorefrontTwoToneIcon from "@material-ui/icons/StorefrontTwoTone";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
class Header extends Component {
  render() {
    const {
      type,
      user,
      hide,
      disablecontrol,
      hidehome,
      invisiblehome,
    } = this.props;
    let showAdmin = showAdminMenu(user);
    let homeColor = "text-muted";
    if (invisiblehome) {
      homeColor = "text-white";
    }
    return (
      <div
        style={{
          backgroundColor: "#ffffff",
          height: "60px",
        }}
      >
        <Navbar
          bg=""
          fixed="top"
          style={{
            backgroundColor: "#ffffff",
            borderBottom: "1px solid #efefef",
            height: "60px",
          }}
        >
          <Navbar.Text style={{ paddingTop: "0px", paddingBottom: "0px" }}>
            {!type && (
              <Link
                to="/home"
                style={{ textDecoration: "none", marginTop: "10px" }}
              >
                <div
                  className="float-left pt-1 text-primary font-weight-bold"
                  style={{ width: "185px" }}
                >
                  <StorefrontTwoToneIcon className="text-primary" /> PALITNA.COM
                </div>
              </Link>
            )}

            {type === "backonly" && (
              <button
                onClick={() => this.props.history.goBack()}
                className="btn-default btn  mx-0 px-0"
              >
                <div className="float-left" style={{ width: "35px" }}>
                  <ArrowBackOutlinedIcon
                    style={{ fontSize: "30px", color: "#1b73e8" }}
                  />
                </div>
              </button>
            )}
            {type === "backandlogo" && (
              <button
                disabled={disablecontrol}
                onClick={() => this.props.history.goBack()}
                className="btn-default btn py-2 px-0"
              >
                <div
                  className="float-left"
                  style={{ paddingTop: "4px", width: "25px" }}
                >
                  <ArrowBackIosIcon
                    style={{ fontSize: "25px", color: "#1b73e8" }}
                  />
                </div>
                <div
                  className="float-left p-0 pt-1 text-primary font-weight-bold"
                  style={{ width: "200px" }}
                >
                  <StorefrontTwoToneIcon className="text-primary" /> PALITNA.COM{" "}
                </div>
              </button>
            )}
          </Navbar.Text>
          <Navbar.Collapse
            className="justify-content-end"
            id="basic-navbar-nav"
          >
            <Navbar.Text>
              {hide !== "profile" && (
                <div className="float-right mx-2">
                  <Link
                    className="btn btn-link ml-0 px-0 my-0 py-0 text-muted"
                    style={{ textDecoration: "none" }}
                    to="/profile"
                  >
                    <SettingsOutlinedIcon
                      style={{
                        fontSize: "25px",
                        marginRight: "5px",
                        marginTop: "4px",
                      }}
                    />
                    {/* {smallpic && (
                      <Image
                        src={smallpic}
                        width="30px"
                        height="30px"
                        alt=""
                        roundedCircle
                        style={{
                          zIndex: "1",
                          marginRight: "5px",
                          marginTop: "2px",
                        }}
                      />
                    )} */}{" "}
                    <span className="pt-1 float-right d-none d-sm-block font-weight-bold text-muted">
                      Settings
                    </span>
                  </Link>
                </div>
              )}
              {!hidehome && (
                <div className="float-right mx-2">
                  <Link
                    className={`btn btn-link ml-0 px-0 my-0 py-0 ${homeColor}`}
                    style={{ textDecoration: "none" }}
                    to="/home"
                  >
                    <HomeOutlinedIcon
                      style={{
                        fontSize: "25px",
                        marginTop: "4px",
                        marginRight: "5px",
                      }}
                    />{" "}
                    <span className="pt-1 float-right d-none d-sm-block font-weight-bold">
                      Home
                    </span>
                  </Link>
                </div>
              )}
              {showAdmin === true && (
                <div className="float-right mx-2">
                  <Link
                    className="btn btn-link ml-0 px-0 my-0 py-0"
                    style={{ textDecoration: "none" }}
                    rel="noopener noreferrer"
                    to="/commandcenter"
                  >
                    <DvrOutlinedIcon
                      style={{
                        fontSize: "24px",
                        color: "#888",
                        marginRight: "5px",
                      }}
                    />{" "}
                    <span className="float-right d-none d-sm-block">Admin</span>
                  </Link>
                </div>
              )}
            </Navbar.Text>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

export default withRouter(Header);
