import React, { Component } from "react";
import { userLogout } from "../../../services/user";
import Header from "../../common/header";
import LoadingSpinnerNav from "../../common/loadingSpinnerNavbar";
import BottomBar from "../../common/bottomBar";
import { Avatar } from "@material-ui/core";
import { Link } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import fire from "../../../services/fire";
import { Button, Modal } from "react-bootstrap";

class PublicViewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
      id: this.props.match.params.id,
      state: "init",
      errors: {},
      loading: true,
    };
  }

  async getItem(products, id) {
    return products.filter((item) => item.id === id);
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  doDelete = async (id) => {
    const productList = this.state.products;
    const userid = this.state.user.uid;
    const filename = this.state.item.filename;
    //delete from user list
    const userRef = fire.firestore().collection("users").doc(userid);
    const newProductList = productList.filter((item) => item.id !== id);
    await userRef.update({ products: newProductList });
    //dlete from products
    const productRef = fire.firestore().collection("products").doc(id);
    await productRef.delete();
    //delete actual files
    const uploadpath = `images/${userid}/products/${filename}`;

    this.deleteFolderContents(uploadpath);
  };

  deleteFolderContents(path) {
    const ref = fire.storage().ref(path);
    ref
      .listAll()
      .then((dir) => {
        dir.items.forEach((fileRef) => {
          this.deleteFile(ref.fullPath, fileRef.name);
        });
        dir.prefixes.forEach((folderRef) => {
          this.deleteFolderContents(folderRef.fullPath);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  deleteFile(pathToFile, fileName) {
    const ref = fire.storage().ref(pathToFile);
    const childRef = ref.child(fileName);
    childRef.delete();
  }

  handleDelete = async () => {
    this.setState({ deleting: true });
    const { id } = this.state;
    await this.doDelete(id);
    this.props.history.push("/home");
  };

  getDialogContents = () => {
    const state = this.state.state;
    let title = "",
      body = "",
      buttonlabel = "",
      buttonaction = "",
      buttoncancel = "";
    if (state === "ready") {
      title = "Delete Product?";
      buttonlabel = "Yes, delete";
      buttonaction = this.handleDelete;
      buttoncancel = this.handleClose;
      body = (
        <>Are you sure you want to delete this item? This cannot be undone.</>
      );
    }
    return [title, body, buttonlabel, buttonaction, buttoncancel];
  };

  displayDialogBox = () => {
    const { show, deleting } = this.state;
    const [title, body, buttonlabel, buttonaction, buttoncancel] =
      this.getDialogContents();
    return (
      <Modal
        show={show}
        animation={false}
        centered
        backdrop="static"
        keyboard={false}
        onHide={this.handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="text-danger">{title}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>
          <Button disabled={deleting} variant="link" onClick={buttoncancel}>
            Cancel
          </Button>
          {deleting !== true && (
            <Button variant="danger" onClick={buttonaction}>
              {buttonlabel}
            </Button>
          )}
          {deleting === true && (
            <Button disabled variant="danger" onClick={buttonaction}>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>{" "}
              Deleting...
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  };
  async getCurrentUserData() {
    //retrieve data from firestore
    let user;
    let id = this.state.id;

    //get the uid
    try {
      const db = fire.firestore();

      let doc = await db.collection("products").where("id", "==", id).get();
      let author;
      doc.forEach((item) => {
        let data = item.data();
        author = data.author;
      });
      //get user here
      doc = await db.collection("users").where("userid", "==", author).get();
      let data;
      doc.forEach((item) => {
        data = item.data();
      });

      user = data;
    } catch (error) {
      console.log(error);
    }
    if (user) {
      const { userid, storename, storecontact, storelink, products, smallpic } =
        user;
      const [item] = await this.getItem(products, id);
      if (!item) {
        this.props.history.push("/home");
      }
      let allowedelete = false;
      if (this.state.user) {
        if (this.state.user.uid === userid) {
          allowedelete = true;
        }
      }
      this.setState({
        data: {
          userid,
          storename,
          storecontact,
          storelink,
          smallpic,
        },
        products,
        state: "ready",
        item,
        allowedelete,
        loading: false,
        user: this.state.user,
      });
    } else {
      //USER NOT FOUND
      //LOGOUT!!!
      await userLogout();
      window.location.reload();
    }
  }

  async componentDidMount() {
    await this.getCurrentUserData();
    window.scrollTo(0, 0);
  }

  handleButtonClick = () => {
    const { data } = this.state;
    window.open(`https://m.me/${data.storecontact}`, "_blank");
  };

  render() {
    const { loading, item, data, allowedelete } = this.state;
    if (loading === true) {
      return (
        <LoadingSpinnerNav type="backonly" hide="profile" hidehome={true} />
      );
    }

    return (
      <React.Fragment>
        <Header type="backonly" hide="profile" hidehome={true} />

        <main className="container">
          <div className="row justify-content-center  mx-0">
            <div className="col-lg-6 text-center mx-auto py-4">
              <div className="row mx-auto">
                <div className="icon-image">
                  <Avatar src={data.smallpic} />
                </div>
                <div className="icon-text font-weight-bold text-left">
                  {data.storename}
                  <br />
                  <Link
                    className="font-weight-normal"
                    to={`/${data.storelink}`}
                  >
                    palitna.com/{data.storelink}
                  </Link>
                </div>
              </div>
              <div className="clearfix"></div>
              <div
                key={item.timestamp}
                className="text-center mx-auto mt-4 mb-2"
              >
                <p className="mb-0 font-weight-bold normalsmallfontsize text-left">
                  {item.caption}
                </p>
                <p className=" normalsmallfontsize text-left text-danger">
                  {item.price}
                </p>
                <img alt="" className="responsive" src={item.image} />
              </div>
              {allowedelete && (
                <div className="text-right pointer my-3 ">
                  <button
                    className="btn btn-danger"
                    onClick={() => this.setState({ show: true })}
                  >
                    <DeleteIcon fontSize="small" /> Delete
                  </button>
                </div>
              )}
            </div>
            <BottomBar
              label="Message to Order"
              onclick={this.handleButtonClick}
            />
          </div>
          {this.displayDialogBox()}
        </main>
      </React.Fragment>
    );
  }
}

export default PublicViewPage;
