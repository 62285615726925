/*
 * Validations
 */
export function isValidEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function isValidMassDb(parishcode) {}

export function isValidParish(id) {
  let code = parseInt(id);
  if (code >= 33 || code <= 0) {
    return false;
  }
  return true;
}

export function isValidFullname(fullname) {
  const emailpattern = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}/;
  const numberpattern = /^\D*(?:\d\D*){2,}$/;
  if (
    emailpattern.test(String(fullname).toLowerCase()) ||
    numberpattern.test(String(fullname).toLowerCase())
  ) {
    return false;
  }
  return true;
}
