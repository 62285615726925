import React from "react";
import { Button } from "react-bootstrap";
import ChatRoundedIcon from "@material-ui/icons/ChatRounded";
const BottomBar = ({ label, selected, onclick }) => {
  return (
    <React.Fragment>
      <div className="my-5"></div>
      <nav
        id="bottomPrompt"
        className="navbar navbar-light  px-0 fixed-bottom bg-white"
        style={{
          backgroundColor: "#ffffff",
          borderTop: "1px solid #efefef",
        }}
      >
        <div className="row mx-auto col-lg-6 justify-content-center py-2">
          <div className="container">
            <Button
              id={selected}
              variant="primary"
              className="btn-block btn-lg"
              onClick={onclick}
            >
              <ChatRoundedIcon /> {label}
            </Button>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default BottomBar;
