import React, { Component } from "react";
import { Link } from "react-router-dom";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";

class SystemBarError extends Component {
  render() {
    const { text, link } = this.props;
    return (
      <div className="mb-2 py-2 text-center" style={{ background: "#f8d7da" }}>
        <span className="" style={{ color: "#721c24" }}>
          <NotificationsActiveIcon
            style={{ fontSize: "16px", marginTop: "-4px" }}
          />{" "}
          <Link style={{ textDecoration: "none", color: "#721c24" }} to={link}>
            {text}
          </Link>
        </span>
      </div>
    );
  }
}

export default SystemBarError;
