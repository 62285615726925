import React, { Component } from "react";
import Joi from "joi-full";
import Input from "./input";
import Select from "./select";
import Textarea from "./textarea";
import { isValidParish } from "../../services/validation";
import InputFocus from "./inputFocus";

class Form extends Component {
  state = {
    data: {},
    errors: {},
  };

  customErrorMessages = (name) => {
    let errorMessage = null;
    if (name === "mobile") {
      errorMessage = "Mobile Number must be valid +63 916 850 8096 format";
    } else if (name === "parish") {
      errorMessage = `If your parish is not listed, please choose "Others"`;
    } else if (name === "dob") {
      // errorMessage = `"Date of Birth" must be valid DD/MM/YYYY format`;
      errorMessage = `Invalid year format`;
    } else if (name === "preferredmasstiming") {
      errorMessage = `Please select your preferred weekend Mass timing`;
    } else if (name === "identification") {
      errorMessage = `"Identification" must be at least 9 characters long`;
    } else if (name === "category") {
      errorMessage = `Please select from the list.`;
    }
    return errorMessage;
  };
  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    let errorMessage;
    for (let item of error.details) {
      errorMessage = this.customErrorMessages(item.path);
      if (errorMessage) {
        errors[item.path] = errorMessage;
      } else {
        errors[item.path] = item.message;
      }
    }
    return errors;
  };

  validateProperty = ({ name, value }) => {
    let errorMessage;
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    if (error) {
      errorMessage = this.customErrorMessages(name);

      if (errorMessage === null) {
        errorMessage = error.details[0].message;
      }
    } else {
      errorMessage = null;
    }
    //return error ? error.details[0].message : null;
    return errorMessage;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      return;
    }
    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    //const errorMessage = this.validateProperty(input);
    // if (errorMessage) errors[input.name] = errorMessage;
    // else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
  };

  handleBlur = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };

    data[input.name] = input.value;
    //check if length of text is more than 1, then try error
    if (input.value) {
      this.setState({ data, errors });
    }
  };

  handleCustomChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;
    /*
     * Here, let us force Preferred Mass Timing to be empty to avoid confusion
     */
    let masstimefield = "preferredmasstiming";
    data[masstimefield] = "";
    if (!isValidParish(input.value)) {
      //if "Others", set this field to 1 to remove errors
      data[masstimefield] = "1";
    }
    /*
     * And clear error message
     */
    delete errors[masstimefield];

    this.setState({ data, errors });
  };

  renderButton(label) {
    return (
      <button
        //disabled={this.validate()}
        className="btn btn-primary btn-lg float-right"
      >
        {label}
      </button>
    );
  }

  renderDisabledBlockButton(label) {
    return (
      <button
        disabled={this.validate()}
        className="btn btn-lg btn-primary btn-block"
      >
        {label}
      </button>
    );
  }

  renderBlockButton(label) {
    return (
      <button
        // disabled={this.validate()}
        className="btn btn-lg btn-primary btn-block"
      >
        {label}
      </button>
    );
  }
  renderLoadingBlockButton(label) {
    return (
      <button disabled className="btn btn-lg btn-primary btn-block">
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>{" "}
        {label}
      </button>
    );
  }

  renderSelect(name, label, options) {
    const { data, errors } = this.state;

    return (
      <Select
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        error={errors[name]}
      />
    );
  }
  renderCustomSelect(name, label, options) {
    const { data, errors } = this.state;

    return (
      <Select
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handleCustomChange}
        error={errors[name]}
      />
    );
  }

  renderScanInput(name, label, type = "text", placeholder = "", showsuccess) {
    const { data, errors } = this.state;
    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        placeholder={placeholder}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        showsuccess={showsuccess}
        error={errors[name]}
      />
    );
  }

  renderBarcodeScanInput(
    name,
    label,
    type = "text",
    placeholder = "",
    showsuccess
  ) {
    const { data, errors } = this.state;
    return (
      <InputFocus
        autoFocus={true}
        type={type}
        name={name}
        value={data[name]}
        label={label}
        placeholder={placeholder}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        showsuccess={showsuccess}
        error={errors[name]}
      />
    );
  }

  renderInput(name, label, type = "text", placeholder = "") {
    const { data, errors } = this.state;
    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        placeholder={placeholder}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        error={errors[name]}
      />
    );
  }

  renderInputFocus(name, label, type = "text", placeholder = "") {
    const { data, errors } = this.state;
    return (
      <Input
        autoFocus={true}
        type={type}
        name={name}
        value={data[name]}
        label={label}
        placeholder={placeholder}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        error={errors[name]}
      />
    );
  }

  renderTextarea(name, label, type = "text", placeholder = "") {
    const { data, errors } = this.state;
    return (
      <Textarea
        type={type}
        name={name}
        value={data[name]}
        label={label}
        placeholder={placeholder}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        error={errors[name]}
      />
    );
  }
}

export default Form;
