import React from "react";
import { Link } from "react-router-dom";
import StorefrontTwoToneIcon from "@material-ui/icons/StorefrontTwoTone";
import "../css/welcome.css";
const WelcomePage = () => {
  return (
    <div className="container my-4 py-4">
      <div className="row align-items-center d-flex py-4 my-4">
        <div className="text-center container h-50 pb-4 mb-4">
          <h1 className="font-weight-bold text-primary">
            <div className="pb-2">
              <StorefrontTwoToneIcon className="logo-big" />
            </div>
            PALITNA.COM
          </h1>
          <h2 className="text-dark font-weight-light py-4">
            Our mission is to help small businesses thrive and succeed.
          </h2>
          <div className="my-4">
            <Link className="btn-primary btn-lg px-4 my-4" to="/register">
              Create your online store
            </Link>
          </div>
          <div className="my-4">
            <Link className="btn-link btn-lg px-4 my-4" to="/login">
              Already have a store? Log In
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
