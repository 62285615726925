import React from "react";

const LoadingSpinner = () => {
  return (
    <React.Fragment>
      <div
        style={{
          position: "absolute",
          width: "200px",
          height: "200px",
          left: "50%",
          top: "50%",
          marginLeft: "-100px",
          marginTop: "-100px",
          zIndex: "1000",
        }}
      >
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoadingSpinner;
