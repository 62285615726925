import React from "react";
import Joi from "joi-full";
import Form from "../../common/form";
import fire from "../../../services/fire";
import { withRouter } from "react-router-dom";
import { get4DigitId } from "../../../utils/utils";
import { getParishes } from "../../../services/parish";
import { Button } from "react-bootstrap";
import { userLogout } from "../../../services/user";
import { logEvent } from "../../../services/log";
// import { getDefaultParishMassTimingsById } from "../../../services/masstimings/parishMassTimings";

class ParishionerDetails extends Form {
  constructor(props) {
    super(props);
    const {
      fullname,
      email,
      dob,
      mobile,
      identification,
      parish,
      // preferredmasstiming,
    } = this.props.userdetails;

    this.state = {
      data: {
        identification: identification || "",
        mobile: mobile || "+65",
        parish: parish || 0,
        dob: dob || "",
        // preferredmasstiming: preferredmasstiming || "",
      },
      fullname,
      email,
      errors: {},
      parishes: [],
      previousParish: parish || 0,
      loading: false,
    };
  }

  schema = {
    identification: Joi.string()
      .trim()
      .min(9)
      .required()
      .label("Identification"),
    mobile: Joi.string()
      .trim()
      // .regex(/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/)
      .regex(/^\+[1-9?\s]{1}[0-9?\s]{9,14}$/)
      .min(10)
      .required()
      .label("Mobile Number"),
    // dob: Joi.date().format("DD/MM/YYYY").raw().label("Date of Birth"),
    dob: Joi.number().min(1890).max(2020).required().label("Year of Birth"),
    parish: Joi.string().trim().required().label("Parish"),
    // preferredmasstiming: Joi.string()
    //   .trim()
    //   .required()
    //   .label("Preferred Weekend Mass Timing"),
  };

  componentDidMount() {
    const parishes = getParishes();

    this.setState({
      parishes,
    });
    window.scrollTo(0, 0);
  }

  doSubmit = async () => {
    const { fullname, email, userid, created } = this.props.userdetails;
    const { data, errors } = this.state;
    this.setState({
      errors,
      loading: true,
    });

    // Call the server
    try {
      //remove any spaces inside the id
      let id = data.identification.toUpperCase().trim().replace(/\s+/g, "");
      let phone = data.mobile.replace(/\s+/g, "");
      let subId = get4DigitId(id);

      var secureid;
      try {
        const secureId = fire.functions("asia-east2").httpsCallable("secureId");

        secureid = await secureId({ input: id });
      } catch (err) {
        logEvent("secure_id_fail", {
          email: email,
          message: err.data.message,
        });
        this.setState({
          data: {
            identification: data.identification.trim(),
            mobile: phone,
            parish: data.parish.trim(),
            dob: data.dob.trim(),
          },
          errors: err,
          loading: false,
        });
        return;
      }

      //call cloud function
      try {
        const checkExistIdentification = fire
          .functions("asia-east2")
          .httpsCallable("checkExistIdentification");

        let result = await checkExistIdentification({ input: id });
        let error;
        if (result.data.status !== 0) {
          if (result.data.status === 1) {
            error = { identification: "Identification already in use." };
          } else if (result.data.status === 2) {
            error = { identification: "Incorrect input." };
          } else {
            error = { identification: "Unable to validate." };
          }
          logEvent("check_exist_identification_fail", {
            email: email,
            message: result.data.message,
          });
          this.setState({
            data: {
              identification: data.identification.trim(),
              mobile: phone,
              parish: data.parish.trim(),
              dob: data.dob.trim(),
            },
            errors: error,
            loading: false,
          });
          return;
        }
      } catch (error) {
        console.log("Error:", error);
        logEvent("user_account_set_fail", {
          email: email,
          message: error.message,
        });
        this.setState({
          data: {
            identification: data.identification.trim(),
            mobile: phone,
            parish: data.parish.trim(),
            dob: data.dob.trim(),
          },
          errors: error,
          loading: false,
        });
        return;
      }
      const currentUser = fire.auth().currentUser;

      await fire.firestore().doc(`users/${currentUser.uid}`).set({
        userid,
        created,
        fullname: fullname.trim(),
        email: email.trim(),
        mobile: phone.trim(),
        dob: data.dob.trim(),
        subid: subId.trim(),
        identification: secureid.data.message.trim(),
        parish: data.parish.trim(),
        // preferredmasstiming: data.preferredmasstiming,
      });
      logEvent("user_account_set_success", {
        email: data.email,
        parish: data.parish,
      });
      this.props.history.push("/account/verify");
    } catch (ex) {
      logEvent("user_account_set_fail", {
        email: email,
        message: ex.message,
      });
      console.error("Unable to Set Data");
    }
  };

  forceLogout = async () => {
    try {
      await userLogout();
      // signed out
      window.location.reload();
    } catch (e) {
      // an error
      window.location.reload();
    }
    //potentially force redirect or refresh here
  };

  render() {
    const { loading, fullname } = this.state;

    // let masstiming;
    // let masstimingoptions = "";
    let massSelection = "";
    let spacer = "";

    // if (data.parish && data.parish !== "0") {
    //   masstiming = getDefaultParishMassTimingsById(data.parish);
    //   masstimingoptions = masstiming.masses;

    //   if (masstimingoptions.length) {
    //     massSelection = this.renderSelect(
    //       "preferredmasstiming",
    //       "Preferred Weekend Mass Timing",
    //       masstimingoptions
    //     );
    //   }
    // }
    spacer = <div className="py-2"></div>;

    return (
      <div>
        <h3 className="text-left text-muted">{fullname}</h3>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput(
            "mobile",
            "Mobile Number",
            "text",
            "(Ex. +63 916 850 8096)"
          )}
          {this.renderInput(
            "identification",
            "NRIC/FIN/Passport",
            "text",
            "(Ex. S1234567Z)"
          )}
          {this.renderInput("dob", "Year of Birth", "text", "(Ex. 1980)")}
          {/* {this.renderInput("dob", "Date of Birth", "text", "DD/MM/YYYY")} */}
          {this.renderSelect(
            "parish",
            "Parish (no changes after submitting)",
            this.state.parishes
          )}
          {massSelection}{" "}
          <p
            className="mb-4  "
            style={{ fontSize: "14px", lineHeight: "140%" }}
          >
            <span className="text-danger">*</span> St Teresa is closed for
            renovation. Please select another parish/church for mass.
          </p>
          <p className="mb-2 " style={{ fontSize: "14px", lineHeight: "140%" }}>
            By clicking "Set Up" to submit my details to the Mass Attendance
            Registration System (MARS), I agree that:
          </p>
          <ul
            className="ml-2 pl-2"
            style={{ fontSize: "12px", lineHeight: "140%" }}
          >
            <li>
              During COVID-19, the Church may collect personal data for visits
              to our premises for the purposes of contact tracing and other
              response measures* in accordance with guidelines issued by the
              authorities{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.pdpc.gov.sg/help-and-resources/2020/03/advisory-on-collection-ofpersonal-data-for-covid-19-contact-tracing %20?subject=PDPA%20COVID-19"
              >
                PDPA COVID-19
              </a>
              . This personal data collected will be deleted when the COVID-19
              measures are lifted, or as directed by the relevant authorities.
            </li>
            <li>
              The Church will safeguard the security and privacy of my personal
              data with reasonable measures in compliance with the Roman
              Catholic Archdiocese of Singapore's{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.catholic.sg/pdpa-policy/"
              >
                PDPA Policy
              </a>
              .
            </li>
          </ul>
          {spacer}
          {!loading && this.renderBlockButton("Set Up")}
          {loading && this.renderLoadingBlockButton("Setting up...")}
          <p className="my-4 " style={{ fontSize: "12px", lineHeight: "140%" }}>
            * For further info, please see our{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://mycatholic.sg/faq"
            >
              FAQ
            </a>
            .
          </p>
        </form>
        <div className="text-center">
          <Button
            variant="link"
            className="btn-lg text-center "
            onClick={this.forceLogout}
          >
            <span className="normalsmallfontsize">Logout</span>
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(ParishionerDetails);
