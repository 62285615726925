import React, { Component } from "react";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";

class SuccessMessage extends Component {
  render() {
    const { message, label, page } = this.props;
    return (
      <React.Fragment>
        <div className="row justify-content-center mx-auto">
          <div className="col-lg-6 text-center">
            <main className="container text-center mx-auto">
              <div className="text-center pt-4 mt-4">
                <CheckCircleOutlineIcon
                  style={{ fontSize: "150px", color: "green" }}
                />
                <h3 className="my-4">{message}</h3>

                <Button
                  variant="primary"
                  className="btn-block btn-lg "
                  onClick={() =>
                    page
                      ? this.props.history.replace(page)
                      : window.location.reload()
                  }
                >
                  {label}
                </Button>
              </div>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(SuccessMessage);
