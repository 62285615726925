import React from "react";
import { Link } from "react-router-dom";
// import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import NotificationsIcon from "@material-ui/icons/Notifications";

const ProfileVerifyStatus = ({ requiredstatus, status, showedit }) => {
  return (
    <React.Fragment>
      {requiredstatus && status && (
        <React.Fragment>
          {/* <p className="text-muted text-center mb-2">
            Verified <VerifiedUserIcon className="text-success" />
          </p> */}
          {showedit && (
            <p>
              <Link className="pt-0 pb-4 text-center" to="/account/edit">
                Edit Store Info
              </Link>
            </p>
          )}
        </React.Fragment>
      )}
      {requiredstatus && !status && (
        <p className="text-muted text-center text-wrap">
          <NotificationsIcon style={{ color: "red", marginTop: "-4px" }} />{" "}
          Please check your email to verify your account
        </p>
      )}
    </React.Fragment>
  );
};

export default ProfileVerifyStatus;
