import React from "react";
import Joi from "joi-browser";
import Form from "../../common/form";
import fire from "../../../services/fire";
import LoadingSpinner from "../../common/loadingSpinner";
import SystemLogo from "../../blocks/systemLogoBlock";
import {
  createWithEmailAndPassword,
  isUserLoggedIn,
} from "../../../services/user";
import { logEvent } from "../../../services/log";
import { isValidFullname } from "../../../services/validation";

class RegisterForm extends Form {
  state = {
    data: {
      fullname: "",
      email: "",
      password: "",
    },
    errors: {},
    loading: true,
    emailaccountcreationfailed: false,
    accountcreateerror:
      "Something went wrong. Please try to login, reset your password or use another account.",
    unrecoverableerror: "We are unable to create your account.",
    emailsenterror:
      "There was a problem sending to your email account. Please try to reset your password or use another account.",
  };

  schema = {
    fullname: Joi.string().trim().required().label("Name"),
    email: Joi.string().trim().email().required().label("Email"),
    password: Joi.string().trim().required().min(8).label("Password"),
  };

  doSubmit = async () => {
    const { data, errors } = this.state;
    //trim spaces
    let fullname = data.fullname.trim();
    let email = data.email.toLowerCase().trim();
    let password = data.password.trim();

    let error;
    let isvalidfullname = isValidFullname(fullname);

    //check if firstname and last name are empty
    if (fullname === "" || !isvalidfullname) {
      error =
        fullname === ""
          ? { fullname: "Please provide Name" }
          : { fullname: "Valid Name only." };
      this.setState({
        data,
        errors: error,
        loading: false,
      });
      return;
    }

    this.setState({
      data: {
        email,
        fullname,
        password,
      },
      errors,
      loading: true,
    });

    // Call the server
    try {
      let result = await createWithEmailAndPassword(email, password);
      if (result && result.code === "success") {
        logEvent("create_email_password_success", {
          email: email,
        });
        const user = result.user;
        let userid = user.uid;

        try {
          const uniqNum = new Date().getTime();
          // console.log("Writing to users collection ");
          //Log to user DB and indicate email verified: false
          await fire.firestore().doc(`users/${userid}`).set({
            email,
            fullname,
            created: uniqNum,
            userid: userid,
            totaltopay: 0,
            totaltocollect: 0,
          });
          logEvent("user_account_create_success", {
            email: email,
          });
          // console.log("Before sending email verification");
          try {
            await user.updateProfile({ displayName: fullname });
            await user.sendEmailVerification();
            // console.log("Done sending email verification");
            logEvent("auto_send_email_verification_success", {
              email: email,
            });
            //Route /home will check if email is verified, if not, show Account Verify page
            this.props.history.push("/home");
          } catch (error) {
            logEvent("auto_send_email_verification_fail", {
              email: email,
              message: error.message,
            });
            console.error("Error sending email: ", error);

            this.setState({
              emailaccountcreationfailed: true,
              loading: false,
              errors: { email: this.state.emailsenterror },
            });
          }
        } catch (ex) {
          console.error("Error creating email and password: ", ex);
          logEvent("user_account_create_fail", {
            email: email,
            message: ex.message,
          });
          this.setState({
            emailaccountcreationfailed: true,
            loading: false,
            errors: { email: this.state.accountcreateerror },
          });
        }
      } else {
        logEvent("create_email_password_fail", {
          email: email,
          message: result.message,
        });
        console.log("Error:", result);
        this.setState({
          emailaccountcreationfailed: true,
          loading: false,
          errors: result,
        });
      }
    } catch (ex) {
      console.log("Errors:", ex);

      logEvent("create_email_password_fail", {
        email: email,
        message: ex.message,
      });
      if (ex.hasOwnProperty("code")) {
        console.log("Errors:", ex);
        let errors;
        if (ex.code === "auth/too-many-requests") {
          errors = { email: ex.message };
        } else {
          errors = {
            email: ex.message,
          };
        }
        this.setState({
          loading: false,
          errors,
        });
      } else {
        this.setState({
          loading: false,
          errors: { email: this.state.unrecoverableerror },
        });
      }
    }
  };

  async componentDidMount() {
    const loggedIn = await isUserLoggedIn();
    // console.log("Logged in: ", loggedIn);
    if (loggedIn) {
      // console.log("Logged in");
      this.props.history.push("/home");
      return;
    }
    this.setState({
      loading: false,
    });
    window.scrollTo(0, 0);
  }

  render() {
    const { loading } = this.state;

    if (loading === true) {
      return <LoadingSpinner />;
    }

    return (
      <React.Fragment>
        <div className="my-4 pt-4"></div>
        <SystemLogo />
        <div className="row justify-content-center  mx-auto">
          <div className="col-lg-4">
            <main className="container">
              <div className="pb-3 pt-2">
                <h4 className="pb-2">Create your online store</h4>
                <form onSubmit={this.handleSubmit}>
                  {this.renderInput("fullname", "NAME", "text", "Your Name")}

                  {this.renderInput("email", "Email", "text", "Your email")}
                  {this.renderInput(
                    "password",
                    "Password",
                    "password",
                    "8 characters or more"
                  )}

                  {!loading && this.renderBlockButton("Create Now")}
                  {loading &&
                    this.renderLoadingBlockButton("Creating...")}
                </form>
              </div>

              <div className="pt-2 pb-5 text-center">
                <p className="normalsmallfontsize">
                  Have an account?{" "}
                  <a rel="noopener noreferrer" href="/login">
                    Log in
                  </a>
                </p>
              </div>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RegisterForm;
