import React, { Component } from "react";
import LoadingSpinner from "../../common/loadingSpinner";
import { getCurrentUserData, userLogout } from "../../../services/user";
import SellerDetailsEdit from "../component/sellerDetailsEdit";
import Header from "../../common/header";
import { enableEditBasicProfile } from "../../../services/settings";

class AccountEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
      data: {
        fullname: "",
        email: "",
        storecontact: "",
        storename: "",
        storelink: "",
      },
      errors: {},
      parish: [],
      loading: true,
    };
  }

  async getCurrentUserData() {
    //retrieve data from firestore
    const uid = this.state.user.uid;
    const user = await getCurrentUserData(uid);

    if (user) {
      const {
        userid,
        created,
        fullname,
        email,
        storecontact,
        storelink,
        storename,
      } = user;

      this.setState({
        data: {
          userid,
          created,
          fullname,
          email,
          storecontact,
          storelink,
          storename,
        },
        loading: false,
      });
    } else {
      //USER NOT FOUND
      //LOGOUT!!!
      await userLogout();
      window.location.reload();
    }
  }

  componentDidMount() {
    if (enableEditBasicProfile()) {
      this.getCurrentUserData();
      window.scrollTo(0, 0);
    } else {
      this.props.history.push("/profile");
    }
  }

  render() {
    const { data, loading, user } = this.state;

    if (loading === true) {
      return <LoadingSpinner />;
    }
    return (
      <React.Fragment>
        <Header type="backonly" smallpic={user.photoURL} hide="profile" />

        <div className="row justify-content-center  mx-auto">
          <div className="col-lg-4">
            <main className="container">
              <div className="pb-3 pt-3">
                <SellerDetailsEdit userdetails={data} />
              </div>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AccountEdit;
