import React from "react";
import { Route, Redirect } from "react-router-dom";
import { enableVerifyEmail } from "../../services/settings";
import AccountVerify from "../account/page/accountVerify";

const ProtectedRoute = ({ component: Component, path, user }) => (
  <Route
    path={path}
    render={(props) => {
      if (!user) {
        return <Redirect to="/welcome" />;
      } else {
        if (enableVerifyEmail() && !user.emailVerified) {
          return <AccountVerify {...props} user={user} />;
        }
      }
      return <Component {...props} user={user} />;
    }}
  />
);

export default ProtectedRoute;
