export const parishes = [
  {
    _id: 1,
    active: true,
    link: "blessedsacrament",
    name: "Blessed Sacrament Church",
    completename: "Blessed Sacrament Church",
    address: "1 Commonwealth Drive Singapore 149603",
    priest: "Rev Fr Johan Wongso, SS.CC",
    phone: "+65 6474 0582 ext. 101",
    email: "bsc.secretariat@catholic.org.sg",
    website: "http://www.bsc.org.sg",
    facebook: "https://www.facebook.com/mybsc.sg",
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 2,
    nexttotalweekendbookingallowed: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4050JOPS",
  },
  {
    _id: 2,
    active: true,
    link: "cathedral",
    name: "Cathedral of the Good Shepherd",
    completename: "Cathedral of the Good Shepherd",
    address: "A, Queen Street Singapore 188533",
    priest: "Rev Fr Jude David",
    phone: "+65 6337 2036",
    email: "cathedral@catholic.org.sg",
    website: "http://cathedral.catholic.sg",
    facebook: "https://www.facebook.com/cathedralofthegoodshepherd",
    instagram: "https://instagram.com/cathedralofthegoodshepherd",
    telegram: "https://t.me/cathedralgdshep",
    youtube: "https://youtube.com/cathedralofthegoodshepherdsg",
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 2,
    nexttotalweekendbookingallowed: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4024L",
  },
  {
    _id: 3,
    active: true,
    link: "christtheking",
    name: "Christ the King",
    completename: "Church of Christ the King",
    address: "2221 Ang Mo Kio Avenue 8 Singapore 569809",
    priest: "Rev Fr John Sim",
    phone: "+65 6459 9958",
    email: "parishoffice@christtheking.com.sg",
    website: "http://www.christtheking.com.sg",
    facebook: "",
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 2,
    nexttotalweekendbookingallowed: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4038L",
  },
  {
    _id: 4,
    active: true,
    link: "divinemercy",
    name: "Divine Mercy",
    completename: "Church of the Divine Mercy",
    address: "19 Pasir Ris Street 72 Singapore 518771",
    priest: "Rev Fr Damian De Wind",
    phone: "+65 6583 3378",
    email: "secretariat@divinemercy.sg",
    website: "http://www.divinemercy.sg",
    facebook: "https://www.facebook.com/divinemercychurch",
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 2,
    nexttotalweekendbookingallowed: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4044K",
  },
  {
    _id: 5,
    active: true,
    link: "holycross",
    name: "Holy Cross",
    completename: "Church of the Holy Cross",
    address: "450 Clementi Avenue 1 Singapore 129955",
    priest: "Rev Fr Henry Siew",
    phone: "+65 6777 5858",
    email: "holycross@singnet.com.sg",
    website: "http://www.holycross.org.sg",
    facebook: "",
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 2,
    nexttotalweekendbookingallowed: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4054D",
  },
  {
    _id: 6,
    active: true,
    link: "holyfamily",
    name: "Holy Family",
    completename: "Church of the Holy Family",
    address: "6 Chapel Road Singapore 429509",
    priest: "Rev Fr Adrian Yeo",
    phone: "+65 6344 0046",
    email: "chf.secretariat@catholic.org.sg",
    website: "http://www.holyfamily.org.sg",
    facebook: "https://www.facebook.com/churchoftheholyfamily",
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 2,
    nexttotalweekendbookingallowed: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4048E",
  },
  {
    _id: 7,
    active: true,
    link: "holyspirit",
    name: "Holy Spirit",
    completename: "Church of the Holy Spirit",
    address: "248 Upper Thomson Road Singapore 574371",
    priest: "Rev Fr Richards Ambrose",
    phone: "+65 6453 6340",
    email: "parish@holyspirit.sg",
    website: "http://www.holyspirit.sg",
    facebook: "https://www.facebook.com/ChurchHolySpiritSg",
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 2,
    nexttotalweekendbookingallowed: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4041L",
  },
  {
    _id: 8,
    active: true,
    link: "holytrinity",
    name: "Holy Trinity",
    completename: "Church of the Holy Trinity",
    address: "20 Tampines Street 11 Singapore 529455",
    priest: "Rev Fr Frederick Quek",
    phone: "+65 6784 2332",
    email: "htadmin@holytrinity.org.sg",
    website: "http://www.holytrinity.org.sg",
    facebook: "https://www.facebook.com/catholicchurchoftheholytrinity.sg",
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 2,
    nexttotalweekendbookingallowed: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4049A",
  },
  {
    _id: 9,
    active: true,
    link: "immaculateheartofmary",
    name: "Immaculate Heart of Mary",
    completename: "Church of the Immaculate Heart of Mary",
    address: "24 Highland Road Singapore 549115",
    priest: "Rev Fr Paul Ngo",
    phone: "+65 6288 9140",
    email: "info@ihm.sg",
    website: "http://www.ihm.sg",
    facebook: "https://www.facebook.com/ChurchOfTheImmaculateHeartOfMary",
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 2,
    nexttotalweekendbookingallowed: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4035A",
  },
  {
    _id: 10,
    active: true,
    link: "ladyoflourdes",
    name: "Lady of Lourdes",
    completename: "Church of the Lady of Lourdes",
    address: "50 Ophir Road Singapore 188690",
    priest: "Rev Fr Michael Sitaram",
    phone: "+65 6294 0624",
    email: "ololsec@gmail.com",
    website: "http://www.lourdes.sg",
    facebook: "https://www.facebook.com/ChurchOfOurLadyOfLourdes",
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 2,
    nexttotalweekendbookingallowed: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4026C",
  },
  {
    _id: 11,
    active: true,
    link: "nativityofblessedvirgin",
    name: "Nativity of the Blessed Virgin Mary",
    completename: "Church of the Nativity of the Blessed Virgin Mary",
    address: "1259 Upper Serangoon Road Singapore 534795",
    priest: "Rev Fr Kenson Koh",
    phone: "+65 6280 0980",
    email: "parishoffice@nativity.sg",
    website: "http://nativity.sg",
    facebook:
      "https://www.facebook.com/ChurchOfTheNativityOfTheBlessedVirginMary",
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 2,
    nexttotalweekendbookingallowed: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4036H",
  },
  {
    _id: 12,
    active: true,
    link: "perpertualsuccour",
    name: "Our Lady of Perpetual Succour",
    completename: "Church of Our Lady of Perpetual Succour",
    address: "31 Siglap Hill Singapore 456085",
    priest: "Rev Fr Anthony Kenny Tan",
    phone: "+65 6241 9565",
    email: "secretariat@olps.sg",
    website: "http://www.olps.sg",
    facebook: "https://www.facebook.com/olps.sg",
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 2,
    nexttotalweekendbookingallowed: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4045F",
  },
  {
    _id: 13,
    active: true,
    link: "queenofpeace",
    name: "Our Lady Queen of Peace",
    completename: "Church of Our Lady Queen of Peace",
    address: "4 Sandy Lane Singapore 437321",
    priest: "Rev Fr Timothy Yeo",
    phone: "+65 6744 2879",
    email: "admin@queenofpeace.sg",
    website: "http://www.queenofpeace.sg",
    facebook: "",
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 2,
    nexttotalweekendbookingallowed: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4046BOPS",
  },
  {
    _id: 14,
    active: true,
    link: "starofthesea",
    name: "Our Lady Star of the Sea",
    completename: "Church of Our Lady Star of the Sea",
    address: "10 Yishun Street 22 Singapore 768579",
    priest: "Rev Fr Gregoire van Giang , MEP",
    phone: "+65 6257 4229",
    email: "olss_secretariat@yahoo.com.sg",
    website: "http://www.olss.sg",
    facebook: "",
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 2,
    nexttotalweekendbookingallowed: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4039G",
  },
  {
    _id: 15,
    active: true,
    link: "risenchrist",
    name: "Risen Christ",
    completename: "Church of the Risen Christ",
    address: "91 Toa Payoh Central Singapore 319193",
    priest: "Rev Fr Edward Lim",
    phone: "+65 6253 2166",
    email: "secretariat@risenchrist.org.sg",
    website: "http://www.risenchrist.org.sg",
    facebook: "",
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 2,
    nexttotalweekendbookingallowed: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4042G42G",
  },
  {
    _id: 16,
    active: true,
    link: "sacredheart",
    name: "Sacred Heart",
    completename: "Church of the Sacred Heart",
    address: "111 Tank Road Singapore 238069",
    priest: "Rev Fr Stephen Yim",
    phone: "+65 6737 9285",
    email: "sacredheartchurch@catholic.org.sg",
    website: "http://www.churchofthesacredheart.sg",
    facebook: "https://www.facebook.com/churchofthesacredheartsg",
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 2,
    nexttotalweekendbookingallowed: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4031F",
    safeentry:
      "https://www.safeentry-qr.gov.sg/tenant/PROD-T08CC4031F-207126-CHURCHOFTHESACREDHEART-SE",
    pewform:
      "https://docs.google.com/forms/d/e/1FAIpQLSee3DxwNNCukgdTnAuWGA1envaf4Wclxh12VCHqFUUpJxsIPg/viewform",
  },
  {
    _id: 17,
    active: true,
    link: "novena",
    name: "St Alphonsus (Novena Church)",
    completename: "Church of St Alphonsus (Novena Church)",
    address: "300 Thomson Road Singapore 307653",
    priest: "Rev Fr Eugene Lee , CSsR",
    phone: "+65 6255 2133",
    email: "secretariatcssr@novenachurch.com",
    website: "http://www.novenachurch.com",
    facebook: "",
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 2,
    nexttotalweekendbookingallowed: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4014FNOV",
  },
  {
    _id: 18,
    active: true,
    link: "stanne",
    name: "St Anne's Church",
    completename: "St Anne's Church",
    address: "66 Sengkang East Way Singapore 548593",
    priest: "Rev Fr Jovita Ho",
    phone: "+65 6386 5072",
    email: "secretariat@stanneonline.org",
    website: "http://www.stanneonline.org",
    facebook: "https://www.facebook.com/st.annes.catholic.church",
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 2,
    nexttotalweekendbookingallowed: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4037D",
  },
  {
    _id: 19,
    active: true,
    link: "stanthony",
    name: "St Anthony",
    completename: "Church of St Anthony",
    address: "25 Woodlands Avenue 1 Singapore 739064",
    priest: "Rev Fr Ignatius Yeo",
    phone: "+65 6368 3804",
    email: "secretary@saint-anthony.org",
    website: "http://www.saint-anthony.org",
    facebook: "https://www.facebook.com/ChurchOfSaintAnthony",
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 2,
    nexttotalweekendbookingallowed: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4040D",
  },
  {
    _id: 20,
    active: true,
    link: "stbernadette",
    name: "St Bernadette",
    completename: "Church of St Bernadette",
    address: "12 Zion Road Singapore 247731",
    priest: "Rev Fr Kamelus Kamus , CICM",
    phone: "+65 6737 3529",
    email: "csb.secretariat@catholic.org.sg",
    website: "http://www.stbernadette.org.sg",
    facebook: "",
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 2,
    nexttotalweekendbookingallowed: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4027K",
  },
  {
    _id: 21,
    active: true,
    link: "stfrancisassisi",
    name: "St Francis of Assisi",
    completename: "Church of St Francis of Assisi",
    address: "200 Boon Lay Avenue Singapore 649964",
    priest: "Rev Fr Peter Koh",
    phone: "+65 6264 0078",
    email: "csfa.secretariat@catholic.org.sg",
    website: "http://www.csfa.sg",
    facebook: "https://www.facebook.com/AssisiSG",
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 2,
    nexttotalweekendbookingallowed: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4051E",
  },
  {
    _id: 22,
    active: true,
    link: "stfrancisxavier",
    name: "St Francis Xavier",
    completename: "Church of St Francis Xavier",
    address: "63A Chartwell Drive Singapore 558758",
    priest: "Rev Fr Benedict Chng",
    phone: "+65 6280 6076",
    email: "secretariat@sfxchurch.sg",
    website: "http://www.sfxchurch.sg",
    facebook: "https://www.facebook.com/sfxchurch.sg",
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 2,
    nexttotalweekendbookingallowed: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4033J",
  },
  {
    _id: 23,
    active: true,
    link: "stignatius",
    name: "St Ignatius",
    completename: "Church of St Ignatius",
    address: "120 King’s Road Singapore 268172",
    priest: "Rev Fr Colin Tan , SJ",
    phone: "+65 6466 0625",
    email: "church@stignatius.org.sg",
    website: "http://www.stignatius.org.sg",
    facebook: "",
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 2,
    nexttotalweekendbookingallowed: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4052A",
  },
  {
    _id: 24,
    active: true,
    link: "stjosephbukittimah",
    name: "St Joseph's Church (Bukit Timah)",
    completename: "St Joseph's Church (Bukit Timah)",
    address: "620 Upper Bukit Timah Road Singapore 678116",
    priest: "Rev Fr Christopher Lee",
    phone: "+65 6769 1666",
    email: "sjcbt.secretariat@catholic.org.sg",
    website: "http://www.stjoseph-bt.org.sg",
    facebook: "",
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 2,
    nexttotalweekendbookingallowed: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4043CBT1",
  },
  {
    _id: 25,
    active: true,
    link: "stjosephvictoria",
    name: "St Joseph's Church (Victoria Street)",
    completename: "St Joseph's Church (Victoria Street)",
    address: "143 Victoria Street Singapore 188020",
    priest: "Rev Fr Jose Lopez",
    phone: "+65 6338 3167",
    email: "sandraross.sjc@catholic.org.sg",
    website: "",
    facebook: "https://www.facebook.com/StJosephVS",
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 2,
    nexttotalweekendbookingallowed: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4032B",
  },
  {
    _id: 26,
    active: true,
    link: "stmaryofangels",
    name: "St Mary of the Angels",
    completename: "Church of St Mary of the Angels",
    address: "5 Bukit Batok East Avenue 2 Singapore 659918",
    priest: "Friar Michael D'Cruz , OFM",
    phone: "+65 6567 3866",
    email: "comms.stmary@catholic.org.sg",
    website: "http://www.stmary.sg",
    facebook: "https://www.facebook.com/stmary.sg",
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 2,
    nexttotalweekendbookingallowed: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4053H",
  },
  {
    _id: 27,
    active: true,
    link: "stmichael",
    name: "St Michael",
    completename: "Church of St Michael",
    address: "17 St Michael’s Road Singapore 327976",
    priest: "Rev Fr Terence Pereira",
    phone: "+65 6291 9272",
    email: "csm.secretariat@catholic.org.sg",
    website: "http://stmichael.catholic.sg/",
    facebook: "",
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 2,
    nexttotalweekendbookingallowed: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4028F",
  },
  {
    _id: 28,
    active: true,
    link: "ststephen",
    name: "St Stephen",
    completename: "Church of St Stephen",
    address: "30 Sallim Road Singapore 387639",
    priest: "Rev Fr Gerard Weerakoon",
    phone: "+65 6747 1433",
    email: "S3tephen@gmail.com",
    website: "http://www.ststephen.sg",
    facebook: "",
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 2,
    nexttotalweekendbookingallowed: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4047J",
  },
  // {
  //   _id: 29,
  //   active: true,
  //   link: "stteresa",
  //   name: "St Teresa",
  //   completename: "Church of St Teresa",
  //   address: "510 Kampong Bahru Road Singapore 099446",
  //   priest: "Rev Fr John Bosco Pereira",
  //   phone: "+65 6271 1184",
  //   email: "stteresa@singnet.com.sg",
  //   website: "http://www.stteresa.org.sg",
  //   facebook: "https://www.facebook.com/stteresa.sg/",
  //   bookingenabled: false,
  // nextbookingenabled: false,
  // totalweekendbookingallowed: 2,
  // nexttotalweekendbookingallowed: 1,
  //   checkinopen: 45,
  //   checkinclose: 45,
  // uen: "T08CC4029B"
  // },
  {
    _id: 30,
    active: true,
    link: "stvincentdepaul",
    name: "St Vincent de Paul",
    completename: "Church of St Vincent de Paul",
    address: "301 Yio Chu Kang Road Singapore 805910",
    priest: "Rev Fr Eugene Chong",
    phone: "+65 6482 0959",
    email: "secretariat@svdp.sg",
    website: "http://www.svdp.sg",
    facebook: "https://www.facebook.com/svdpsg",
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 2,
    nexttotalweekendbookingallowed: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4034E",
  },
  {
    _id: 31,
    active: true,
    link: "stspeterandpaul",
    name: "Sts Peter and Paul",
    completename: "Church of Sts Peter and Paul",
    address: "225A Queen Street Singapore 188551",
    priest: "Rev Fr Edward Lim , OCD",
    phone: "+65 6337 2585",
    email: "sts_peternpaul@singnet.com.sg",
    website: "http://sppchurch.org.sg",
    facebook: "https://www.facebook.com/sppchurchsg",
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 2,
    nexttotalweekendbookingallowed: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T08CC4023DP2A",
    uennotes:
      "Displayed name: The Titular Roman Catholic Archbishop of Singapore",
  },
  {
    _id: 32,
    active: true,
    link: "transfiguration",
    name: "Transfiguration",
    completename: "Church of the Transfiguration",
    address: "51 Punggol Central Singapore 828725",
    priest: "Rev Fr Alphonsus Dominic",
    phone: "+65 6341 9718",
    email: "secretary@transfiguration.sg",
    website: "http://www.transfiguration.sg",
    facebook: "",
    bookingenabled: true,
    nextbookingenabled: true,
    totalweekendbookingallowed: 2,
    nexttotalweekendbookingallowed: 1,
    checkinopen: 45,
    checkinclose: 45,
    uen: "T15CC0001D",
  },
  // {
  //   _id: 33,
  //   active: false,
  //   link: "",
  //   name: "Others",
  //   completename: "Others",
  //   address: "",
  //   priest: "",
  //   phone: "",
  //   email: "",
  //   website: "",
  //   facebook: "",
  // },
];

export function getParishes() {
  return parishes;
}

export function getTotalWeekendBookingAllowedById(id) {
  const [parish] = parishes.filter((g) => g._id === parseInt(id));
  return parish.totalweekendbookingallowed;
}

export function getNextTotalWeekendBookingAllowedById(id) {
  const [parish] = parishes.filter((g) => g._id === parseInt(id));
  return parish.nexttotalweekendbookingallowed;
}

export function getParishCheckinOpenById(id) {
  const [parish] = parishes.filter((g) => g._id === parseInt(id));
  return parish.checkinopen;
}

export function getParishCheckinCloseById(id) {
  const [parish] = parishes.filter((g) => g._id === parseInt(id));
  return parish.checkinclose;
}

export function getParishBookingAllowedById(id) {
  const [parish] = parishes.filter((g) => g._id === parseInt(id));
  return parish.bookingenabled;
}

export function getParishBookingAllowedByCode(code) {
  const [parish] = parishes.filter((g) => g.link === code);

  return parish.bookingenabled;
}

export function getParishNextBookingAllowedByCode(code) {
  const [parish] = parishes.filter((g) => g.link === code);

  if (parish.hasOwnProperty("nextbookingenabled")) {
    return parish.nextbookingenabled;
  }
  return false;
}

export function getParishSafeEntryIdById(id) {
  const [parish] = parishes.filter((g) => g._id === parseInt(id));
  if (parish.hasOwnProperty("safeentry")) {
    return parish.safeentry;
  }
  return "";
}

export function getParishPewFormIdById(id) {
  const [parish] = parishes.filter((g) => g._id === parseInt(id));
  if (parish.hasOwnProperty("pewform")) {
    return parish.pewform;
  }
  return "";
}

export function getParishCompleteNameById(id) {
  const [parish] = parishes.filter((g) => g._id === parseInt(id));
  return parish.completename;
}

export function getParishPriestById(id) {
  const [parish] = parishes.filter((g) => g._id === parseInt(id));
  return parish.priest;
}
export function getParishNameById(id) {
  const [parish] = parishes.filter((g) => g._id === parseInt(id));

  return parish.name;
}
export function getParishLinkById(id) {
  const [parish] = parishes.filter((g) => g._id === parseInt(id));

  return parish.link;
}
export function getParishByCode(code) {
  const [parish] = parishes.filter((g) => g.link === code);

  return parish;
}
export function getParishCompleteNameByCode(code) {
  const [parish] = parishes.filter((g) => g.link === code);

  return parish.completename;
}

export function getParishIdByCode(code) {
  const [parish] = parishes.filter((g) => g.link === code);

  return parish._id;
}
