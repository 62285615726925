import React, { Component } from "react";
import SystemLogo from "../blocks/systemLogoBlock";
import SimpleMessage from "../blocks/simpleMessageBlock";

class PageNotFound extends Component {
  render() {
    const { user } = this.props;
    return (
      <React.Fragment>
        <div className="row justify-content-center mx-auto">
          <div className="col-lg-4">
            <main className="container mt-4 pt-4">
              <SystemLogo />
              {!user && (
                <SimpleMessage
                  title=""
                  content="Seems like the page does not exist."
                  link="/login"
                  label="Show me log in page"
                />
              )}
              {user && (
                <SimpleMessage
                  title=""
                  content="Seems like the page does not exist."
                  link="/home"
                  label="Back to Home"
                />
              )}
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PageNotFound;
