import React from "react";
import Header from "./header";

const LoadingSpinnerNav = (props) => {
  const { type, user, disablecontrol, ...rest } = props;
  return (
    <React.Fragment>
      <Header
        type={type}
        user={user}
        disablecontrol={disablecontrol}
        {...rest}
      />

      <div
        style={{
          position: "absolute",
          width: "200px",
          height: "200px",
          left: "50%",
          top: "50%",
          marginLeft: "-100px",
          marginTop: "-100px",
          zIndex: "1000",
        }}
      >
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoadingSpinnerNav;
