import React, { Component } from "react";
import { Image } from "react-bootstrap";
import {
  getParishCompleteNameById,
  getParishIdByCode,
  getParishPriestById,
} from "../../services/parish";
import { isValidParish } from "../../services/validation";

class ParishPriest extends Component {
  images = [
    { id: 0, photo: require("../../img/logo/logo_archdiocese_white_150.png") },
    { id: 1, photo: require("../../img/priest/blessedsacrament.jpg") },
    { id: 2, photo: require("../../img/priest/cathedral.jpg") },
    { id: 3, photo: require("../../img/priest/christtheking.jpg") },
    { id: 4, photo: require("../../img/priest/divinemercy.jpg") },
    { id: 5, photo: require("../../img/priest/holycross.jpg") },
    { id: 6, photo: require("../../img/priest/holyfamily.jpg") },
    { id: 7, photo: require("../../img/parish/300/holyspirit.jpg") },
    { id: 8, photo: require("../../img/priest/holytrinity.jpg") },
    { id: 9, photo: require("../../img/priest/immaculateheartofmary.jpg") },
    { id: 10, photo: require("../../img/priest/ladyoflourdes.jpg") },
    {
      id: 11,
      photo: require("../../img/priest/nativityofblessedvirgin.jpg"),
    },
    { id: 12, photo: require("../../img/priest/perpertualsuccour.jpg") },
    { id: 13, photo: require("../../img/priest/queenofpeace.jpg") },
    { id: 14, photo: require("../../img/priest/starofthesea.jpg") },
    { id: 15, photo: require("../../img/priest/risenchrist.jpg") },
    { id: 16, photo: require("../../img/priest/sacredheart.jpg") },
    { id: 17, photo: require("../../img/priest/novena.jpg") },
    { id: 18, photo: require("../../img/priest/stanne.jpg") },
    { id: 19, photo: require("../../img/priest/stanthony.jpg") },
    { id: 20, photo: require("../../img/priest/stbernadette.jpg") },
    { id: 21, photo: require("../../img/priest/stfrancisassisi.jpg") },
    { id: 22, photo: require("../../img/priest/stfrancisxavier.jpg") },
    { id: 23, photo: require("../../img/priest/stignatius.jpg") },
    { id: 24, photo: require("../../img/priest/stjosephbukittimah.jpg") },
    { id: 25, photo: require("../../img/priest/stjosephvictoria.jpg") },
    { id: 26, photo: require("../../img/priest/stmaryofangels.jpg") },
    { id: 27, photo: require("../../img/priest/stmichael.jpg") },
    { id: 28, photo: require("../../img/priest/ststephen.jpg") },
    { id: 29, photo: require("../../img/parish/300/stteresa.jpg") },
    { id: 30, photo: require("../../img/priest/stvincentdepaul.jpg") },
    { id: 31, photo: require("../../img/priest/stspeterandpaul.jpg") },
    { id: 32, photo: require("../../img/priest/transfiguration.jpg") },
    { id: 33, photo: require("../../img/logo/logo_archdiocese_white_150.png") },
  ];
  constructor(props) {
    super(props);
    const { parish, parishId, parishCode } = props;

    this.state = {
      parish,
      parishId,
      parishCode,
    };
  }
  getImageDisplay = (id) => {
    let imagedisplay;
    let parishId = id;
    if (!isValidParish(parishId)) {
      parishId = 33;
    }
    [imagedisplay] = this.images.filter((img) => img.id === parseInt(parishId));
    return imagedisplay.photo;
  };
  getUniqueParishId = () => {
    const { parish, parishId, parishCode } = this.state;
    let id;
    if (parishId) {
      id = parishId;
    } else if (parishCode) {
      id = getParishIdByCode(parishCode);
    } else if (parish) {
      id = parish._id;
    } else {
      id = 33;
    }
    return id;
  };
  getParishPriestName = (id) => {
    let display = (
      <h4 className="pt-1 text-center text-muted">
        <span className="font-italic">my</span>CatholicSG
      </h4>
    );
    if (!isValidParish(id)) {
      return display;
    }
    if (id) {
      display = getParishPriestById(id);
      let parish = getParishCompleteNameById(id);
      //check if need to split
      if (display.indexOf("(") !== -1) {
        let first = display.split("(");
        let second = first[1].split(")");
        display = (
          <React.Fragment>
            <h4 className="pt-1 mb-1 text-center text-muted">{first[0]}</h4>
            <h4 className="text-center text-muted">({second[0]})</h4>
          </React.Fragment>
        );
      } else {
        display = (
          <React.Fragment>
            <h5 className="pt-1 mb-0  pb-0 text-center">{display}</h5>
            <p className="my-0 pt-0 text-center text-muted defaultfontsize">
              {parish}
            </p>
            <div className="py-1"></div>
          </React.Fragment>
        );
      }
    }
    return display;
  };
  render() {
    const id = this.getUniqueParishId();
    return (
      <nav className="navbar navbar-light bg-white flex-column">
        <a className="navbar-brand mx-auto" href="# ">
          <Image
            src={this.getImageDisplay(id)}
            width="150px"
            height="150px"
            alt="Parish Priest"
            roundedCircle
          />
        </a>
        {this.getParishPriestName(id)}
      </nav>
    );
  }
}

export default ParishPriest;
