import React from "react";
import StorefrontTwoToneIcon from "@material-ui/icons/StorefrontTwoTone";

const SystemLogo = () => {
  return (
    <nav className="navbar navbar-light text-center flex-column">
      <a className="navbar-brand mx-auto" href="# ">
        <h1 className="font-weight-bold text-primary">
          <div className="py-2">
            <StorefrontTwoToneIcon className="logo-big" />
          </div>
          PALITNA.COM
        </h1>
      </a>
    </nav>
  );
};

export default SystemLogo;
