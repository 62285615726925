import React, { Component } from "react";
import { Link } from "react-router-dom";

class LinkButton extends Component {
  showButton(type, link, label) {
    let button;
    if (type === "link") {
      button = <Link to={link}>{label}</Link>;
    } else if (type === "button") {
      button = (
        <Link
          className="btn-lg btn btn-block btn-primary"
          role="button"
          to={link}
        >
          {label}
        </Link>
      );
    } else if (type === "frame") {
      button = (
        <Link
          className="btn-lg btn btn-block btn-outline-primary"
          role="button"
          to={link}
        >
          {label}
        </Link>
      );
    } else if (type === "framegray") {
      button = (
        <Link
          className="btn-lg btn btn-block btn-outline-secondary"
          style={{ border: "1px solid #dddddd" }}
          role="button"
          to={link}
        >
          {label}
        </Link>
      );
    } else if (type === "frameprimary") {
      button = (
        <Link
          className="btn-lg btn btn-block btn-outline-primary"
          style={{ border: "1px solid #dddddd" }}
          role="button"
          to={link}
        >
          {label}
        </Link>
      );
    } else {
      button = (
        <Link
          className="btn-lg btn btn-block btn-primary"
          role="button"
          to={link}
        >
          {label}
        </Link>
      );
    }
    return button;
  }
  render() {
    const { type, link, label } = this.props;
    return (
      <div className="text-center my-2">
        <p className="py-3 defaultfontsize">
          {this.showButton(type, link, label)}
        </p>
      </div>
    );
  }
}

export default LinkButton;
